<template>
  <v-card flat tile class="cart-info-card h-100 px-6 py-3 px-sm-3 py-sm-3">
    <v-row no-gutters align="center">
      <v-col cols="12">
        <v-row
          no-gutters
          class="text-body-2 default--text font-weight-bold my-5"
        >
          <v-col cols="4">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ $t("orders.header.deliveryStatus") }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 font-weight-bold my-5">
          <v-col cols="4">
            <span class="primary--text">#{{ order.orderId }}</span>
          </v-col>
          <v-col cols="4">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
          <v-col cols="4">
            <OrderStatusDeliveryBadge
              v-if="order.deliveryStatusId && order.orderStatusId != 10"
              v-bind:order="order"
              :disabled="true"
              class="default--text"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="order-buttons">
        <v-btn
          v-if="order.isEditable"
          @click="editOrder(order.orderId)"
          color="secondary"
          outlined
          depressed
        >
          <v-icon>$edit</v-icon>
          {{ $t("orders.button.edit") }}
        </v-btn>
        <v-btn
          v-if="order.isPayable"
          @click="showPayment"
          color="secondary"
          outlined
          depressed
        >
          <v-icon>$creditCard</v-icon>
          {{ $t("orders.button.retryPayment") }}
        </v-btn>

        <v-btn @click="printOrder" color="secondary" depressed outlined>
          {{ $t("orders.button.print") }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click.stop.prevent="addAllToCart(order.orderId)"
          depressed
        >
          <v-icon>$cart</v-icon>
          {{ $t("orders.button.reorder") }}
        </v-btn>
        <v-btn
          v-if="order.isDeletable"
          color="error"
          @click="deleteOrder(order.orderId)"
          depressed
        >
          <v-icon>$delete</v-icon>
          {{ $t("orders.button.delete") }}
        </v-btn>
        <v-btn
          v-if="order.orderStatusId == 8 || order.orderStatusId == 18"
          @click.prevent="handleReceiptDownload(receiptUrl, order.orderId)"
          target="_blank"
          color="secondary"
          depressed
          outlined
        >
          {{ $t("orders.button.ddr") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.order-buttons {
  .v-btn {
    width: calc(50% - 4px);
    margin: 2px;
  }
  @media (max-width: 960px) {
    .v-btn {
      width: calc(100% - 4px);
    }
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderStatusDeliveryBadge from "@/components/orders/OrderStatusDeliveryBadge.vue";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderStatusDeliveryBadge },
  props: ["order"],
  computed: {
    receiptUrl() {
      let orderId = this.order.orderId;
      return `/ebsn/api/order/bill?order_id=${orderId}&type=pdf`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
